
import { Component, Vue } from "vue-property-decorator";
import Member from "@/types/Member";
import MemberComponent from "@/components/Member.vue";

@Component({
  components: {
    MemberComponent,
  },
})
export default class Impressum extends Vue {
  commitee: Member[] = [
    {
      role: "1. Vorsitzender",
      name: "Rüdiger Beiser",
      street: "Hintergasse 47",
      town: "55288 Partenheim",
      telephone: "06732/9659173",
    },
    {
      role: "2. Vorsitzender",
      name: "Egolf Steinbacher",
      street: "Neugasse 9",
      town: "55234 Wendelsheim",
      telephone: "06734/8792",
    },
    {
      role: "Kassenwart",
      name: "Ewald Witter",
      street: "Am Backhausgarten 7",
      town: "55237 Flonheim",
      telephone: "06734/8578",
    },
  ];
}
