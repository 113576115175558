
import { Component, Prop, Vue } from "vue-property-decorator";
import Member from "@/types/Member";

@Component
export default class MemberComponent extends Vue {
  @Prop({
    required: true,
  })
  private member!: Member;
}
